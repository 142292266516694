export const firebaseConfig = {
  admin: {
    apiKey: "AIzaSyAgY9QDjme7g0q0Tr8VNlowcZGrq6d6854",
    authDomain: "tranquifit-b5434.firebaseapp.com",
    projectId: "tranquifit-b5434",
    storageBucket: "tranquifit-b5434.appspot.com",
    messagingSenderId: "18345884530",
    appId: "1:18345884530:web:679538fdb5aedd31223beb",
    measurementId: "G-VC62Y4DPWS"
  },
  user: {
    apiKey: "AIzaSyAgY9QDjme7g0q0Tr8VNlowcZGrq6d6854",
    authDomain: "tranquifit-b5434.firebaseapp.com",
    projectId: "tranquifit-b5434",
    storageBucket: "tranquifit-b5434.appspot.com",
    messagingSenderId: "18345884530",
    appId: "1:18345884530:web:2fd9163188df5fec223beb",
    measurementId: "G-R6ZPM5HHJW"
  },
  superAdmin: {
    apiKey: "AIzaSyAgY9QDjme7g0q0Tr8VNlowcZGrq6d6854",
    authDomain: "tranquifit-b5434.firebaseapp.com",
    projectId: "tranquifit-b5434",
    storageBucket: "tranquifit-b5434.appspot.com",
    messagingSenderId: "18345884530",
    appId: "1:18345884530:web:37396f8b78a02bed223beb",
    measurementId: "G-X7RPCVQDSV"
  },
  auth: {
    apiKey: "AIzaSyAgY9QDjme7g0q0Tr8VNlowcZGrq6d6854",
    authDomain: "tranquifit-b5434.firebaseapp.com",
    projectId: "tranquifit-b5434",
    storageBucket: "tranquifit-b5434.appspot.com",
    messagingSenderId: "18345884530",
    appId: "1:18345884530:web:7a6fe5a912cf3f21223beb",
    measurementId: "G-F43V8865TH"
  },
  site: {
    apiKey: "AIzaSyAgY9QDjme7g0q0Tr8VNlowcZGrq6d6854",
    authDomain: "tranquifit-b5434.firebaseapp.com",
    projectId: "tranquifit-b5434",
    storageBucket: "tranquifit-b5434.appspot.com",
    messagingSenderId: "18345884530",
    appId: "1:18345884530:web:988e0502ead45448223beb",
    measurementId: "G-DXGPJCL87Q"
  }
};

export const algoliaConfig = {
  appId: 'A1RZXSEWUY',
  searchKey: 'eca84c2d7a495d17ab40610c88234a48',
  businessId: null
};

export const reCaptchaConfig = {
  siteKey: '6LcLsbwmAAAAAPt-P1ZohOYNjmjcUp1LGbV_sw_P',
};

export const sentryConfig = {
  adminDsn:
    'https://376596862417bd67a1ccda3057af1ced@o4507429332123648.ingest.de.sentry.io/4507532773359696',
  userDsn:
    'https://fd0f7ce64d168057d6b41d29acfa2f51@o4507429332123648.ingest.de.sentry.io/4507532814843984',
  functionsDsn:
    'https://c8fef93b6575ad499ddbc007787d2359@o4507429332123648.ingest.de.sentry.io/4507532816875600',
};

export const banner = '';

export const authUrl = 'https://authentication.swimplannr.be';
export const superAdmin = 'https://super-admin.swimplannr.be';
