export function getBusinessIdFromOrigin(hostname: string, topDomain?: string) {
  const hostnameWithoutHttp = hostname
    .replace(`https://`, '')
    .replace(`http://`, '');

  if (hostnameWithoutHttp === '127.0.0.1') {
    return 'localhost2';
  }
  if (hostnameWithoutHttp === 'localhost') {
    return 'localhost';
  }
  if (topDomain && !hostnameWithoutHttp.includes(topDomain)) {
    throw new Error(`Invalid hostname: ${hostname}`);
  }

  const hostNameWithoutWWW = hostnameWithoutHttp.replace('www.', '');
  const hostNameWithoutTopDomain = hostNameWithoutWWW.replace(`.${topDomain}`, '');
  const splittedHostname = hostNameWithoutTopDomain.split('.');
  if (!splittedHostname[0]) {
    throw new Error(`Invalid hostname: ${hostname}`);
  }
  // TODO check in db if some business has this url as a custom domain
  return splittedHostname[0];
}